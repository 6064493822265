<template>
  <div>
    <div style="padding-top: 10px">
      <span>
        <span>所属公司:</span>
        <el-select
          v-model="Company1"
          clearable
          placeholder="请选择"
          @change="togetArea"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <span style="margin-left: 25px; margin-right: 25px">
        <span>所属部门:</span>
        <el-select
          v-model="deptmentName"
          clearable
          @change="togetPost"
          placeholder="请选择"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <span style="margin-left: 25px; margin-right: 25px">
        <span>所属岗位:</span>
        <el-select v-model="Area" clearable placeholder="请选择">
          <el-option
            v-for="item in option3"
            :key="item.value"
            @change="gettitle"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <el-checkbox
        label="已完成"
        v-model="ischeck"
        true-label="2"
        @change="changBox"
      ></el-checkbox>
      <el-checkbox
        label="未完成"
        v-model="ischeck"
        true-label="1"
        @change="changBox"
      ></el-checkbox>
      <el-checkbox
        label="发现问题点"
        v-model="ischeck"
        true-label="4"
        @change="changBox"
      ></el-checkbox>
      <el-button
        @click="toSearch"
        type="success"
        icon="el-icon-search"
        class="find"
        >搜索</el-button
      >
    </div>
    <div class="mid_box">
      <el-button type="primary" class="edit" @click="toChoose"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <img
        style="cursor: pointer; margin: 0 10px"
        @click="delItem"
        src="../../assets/images/Examine_img/del.png"
        alt=""
      />
      <el-button type="primary" class="edit" v-show="isShow" @click="toSearch2"
        >股份公司内审</el-button
      >
    </div>
    <el-table
      ref="multipleTable"
      id="table"
      :data="tableData.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      highlight-current-row
      @row-dblclick="change"
      @selection-change="handleSelectionChange"
      @select="selectChange"
      @select-all="selectAll"
      @row-click="handleRow"
      :cell-style="cellStyle"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column label="岗位" prop="postName" width="120">
      </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="230">
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="Company1"
          placeholder="请选择"
          class="Company_choose"
          @change="togetArea"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属部门:</span>
        <el-select
          v-model="deptmentName1"
          placeholder="请选择"
          @change="togetPost"
          class="Company_choose"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属岗位:</span>
        <el-select
          v-model="Area1"
          placeholder="请选择"
          @change="gettitle"
          class="Company_choose"
        >
          <el-option
            v-for="item in option3"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- //获取的抽题列表为岗位 -->
      <!-- <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 0.8%">抽取题目:&ensp;</span>
          <div class="topic">
            <div>职能</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img
              src="../../assets/images/Examine_img/top.png"
              @click="isshow = !isshow"
              alt=""
            />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in titlelist"
              :key="index"
            >
              <span
                style="
                  margin: 10px;
                  margin-left: 1.4%;
                  margin-bottom: 0px;
                  margin-top: 0px;
                "
                >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ensp;</span
              >
              <div class="topic1">
                <div>{{ item.functionName }}</div>
                <div class="Centernumbet">
                  <span
                    @click="tocheckCount(item.titleCount, item.commonCount)"
                    >{{ item.titleCount }}</span
                  >
                </div>
                <div id="number">
                  <img
                    @click="sub(item)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt=""
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled; margin-top: 3%"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div> -->
      <!-- //获取的抽题列表为文件 -->
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 0.8%">抽取题目:&ensp;</span>
          <el-table
            :data="titlelist"
            @selection-change="selectionDraw"
            style="width: 100%"
            height="250"
          >
            <el-table-column prop="fileName" label="文件名称" width="150">
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
            <el-table-column prop="titleCount" label="总题数">
            </el-table-column>
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddInsertAreaAudit"
          >确定抽题</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="题目详情" :visible.sync="dialogVisibletitle" width="30%">
      <el-form label-width="80px">
        <el-form-item label="专属">
          <el-input v-model="Stitle"></el-input>
        </el-form-item>
        <el-form-item label="通用">
          <el-input v-model="commontitle"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibletitle = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibletitle = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post } from '../../api/http'

export default {
  data() {
    return {
      fromQuery: '',
      elementHeight: 0,
      dialogVisible: false,
      dialogVisibletitle: false,
      options: [],
      option2: [],
      tableData: [],
      tableData2: [],
      Company: '',
      Area: '',
      Stitle: '',
      commontitle: '',
      extractCount: 0,
      Company1: '',
      Area1: '',
      isshow: false,
      number: '',
      curArea: '',
      titlelist: [],
      selectDrawVlue: [],
      CurId: '',
      CurName: '',
      deptmentName: '',
      deptmentName1: '',
      option3: [],
      curPost: '',
      ischeck: '',
      start: 0,
      end: 10,
      answer: '',
      isShow: null,
      currentPage1: null,
      currentPage: null,
      curSize: 10,
      Ctdata: '',
      searchIns: null,
      dialogVisibleC: false,
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != 'Flies_topic') {
      to.meta.ifDoFresh = true
    }
    next()
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false
      //this.getArea()
      this.Company1 = ''
      this.currentPage1 = 1
      this.curSize = 10
      this.end = 10
      this.start = 0
      this.options = this.$store.state.companyUserList
      this.fromQuery = JSON.parse(sessionStorage.getItem('fromQuery'))
      this.getdata()
      this.getElementHeight()
    }
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  methods: {
    async getdata() {
      get('/api/FileAudit/ShowButton').then((res) => {
        this.isShow = res.data
      })
      await get(
        '/api/FileAudit/GetList?AuditType=' + Number(this.fromQuery)
      ).then((res) => {
        this.tableData = res.data
        this.tableData2 = res.data
      })
    },
    tocheckCount(e, v) {
      this.Stitle = e - v
      this.commontitle = v
      this.dialogVisibletitle = true
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52)
      })
    },
    gettitle(e) {
      this.curPost = e
      this.option2.forEach((i) => {
        if (i.id === e) {
          this.curArea = i
        }
      })
      this.titlelist = ''
      console.log(this.deptmentName)
      get(
        '/api/FileAudit/GetTitleList?CompId=' +
          this.Company1 +
          '&DeptId=' +
          this.deptmentName1 +
          '&PostId=' +
          e
      ).then((res) => {
        this.titlelist = res.data
      })
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = ''
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount
    },
    selectionDraw(e) {
      this.selectDrawVlue = e
    },
    async toAddInsertAreaAudit() {
      ///获取的抽题列表为岗位
      // this.extractCount = 0
      // this.titlelist.forEach((e) => {
      //   this.extractCount += Number(e.extractCount)
      // })
      // if (this.extractCount === 0) {
      //   this.$message({
      //     type: 'info',
      //     message: '抽取的题目为空',
      //   })
      //   return
      // }
      // if (this.titlelist == '') {
      //   return
      // }
      this.curCPY = ''
      this.curDept = ''
      this.curPostName = ''
      this.options.forEach((e) => {
        if (e.id === this.Company1) {
          this.curCPY = e.companyName
        }
      })
      this.option2.forEach((e) => {
        if (e.id === this.deptmentName1) {
          this.curDept = e.deptmentName
        }
      })
      this.option3.forEach((e) => {
        if (e.id === this.curPost) {
          this.curPostName = e.postName
        }
      })
      var data = {
        jsonData: {
          // data: JSON.stringify(this.titlelist), //获取的抽题列表为岗位
          data: JSON.stringify(this.selectDrawVlue), //获取的抽题列表为文件
        },
        fileAudit: {
          companyId: this.Company1 + '',
          companyName: this.curCPY,
          deptId: this.deptmentName1 + '',
          deptName: this.curDept,
          postId: this.curPost + '',
          postName: this.curPostName,
          remark: '',
          chargeUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          planId: '',
          saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
          saveUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
          auditType: Number(this.fromQuery),
        },
      }
      await post(
        '/api/FileAudit/InsertFileAudit?CompId=' +
          this.Company1 +
          '&DeptId=' +
          this.deptmentName1 +
          '&PostId=' +
          this.curPost,
        data
      )
        .then((res) => {
          if (res.code == '400') {
            this.$message({
              type: 'info',
              message: res.message,
            })
          }
          this.dialogVisible = false
        })
        .then(() => {
          this.getdata().then(() => {
            this.change(this.tableData[0])
          })
        })
    },
    cellStyle() {
      return 'cursor:pointer;'
    },
    change(e) {
      console.log(e)
      sessionStorage.setItem('Fitem', JSON.stringify(e))
      this.$router.push(
        '/Flies_topic?Id=' +
          e.id +
          '&CpyID=' +
          e.companyId +
          '&deptID=' +
          e.deptId +
          '&postId=' +
          e.postId
      )
    },
    delItem() {
      this.$confirm(
        '此操作将永久删除该' + this.CurName + '检查文件',
        '是否继续?',
        '提示',

        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          deleteMethod('/api/FileAudit?Id=' + this.CurId).then(() => {
            this.getdata()
          })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    toChoose() {
      this.dialogVisible = true
      this.Area1 = this.Area
      if (this.Area) {
        this.gettitle(this.Area)
        //this.dialogVisible = true;
      }
    },
    chooseCt() {
      this.dialogVisibleC = true
      get('/api/AuditType/GetTree').then((res) => {
        this.Ctdata = res.data
      })
    },
    togetPost(e) {
      this.deptmentName1 = e
      this.Area1 = ''
      this.Area = ''
      get('/api/Post?CompId=' + this.Company1 + '&DeptId=' + e).then((res) => {
        console.log(res)
        this.option3 = res.data
      })
    },
    togetArea(e) {
      this.Company1 = e
      this.deptmentName = ''
      this.deptmentName1 = ''
      this.Area1 = ''
      this.Area = ''
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        this.option2 = res.data
      })
    },
    handleRow(e) {
      console.log(e)
      this.CurId = e.id
      this.CurName = '关于' + e.companyName
      this.$refs.multipleTable.toggleRowSelection(e)
      this.selectChange(this.handleSelectionList)
    },
    toSearch() {
      console.log(this.Area)
      this.searchIns = false
      get(
        '/api/FileAudit/GetByFileAudit?CompId=' +
          this.Company1 +
          '&DeptId=' +
          this.deptmentName1 +
          '&PostId=' +
          this.Area +
          '&Status=' +
          this.ischeck
      ).then((res) => {
        console.log(res)
        this.tableData = res.data
      })
    },
    toSearch2() {
      this.searchIns = true
      get(
        '/api/FileAudit/GetByNewFileAudit?CompId=' +
          this.Company1 +
          '&DeptId=' +
          this.deptmentName1 +
          '&PostId=' +
          this.Area +
          '&Status=' +
          this.ischeck
      ).then((res) => {
        console.log(res)
        this.tableData = res.data
      })
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value
    },
    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
      }
    },
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
      this.selectChange(this.handleSelectionList)
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1
      }
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    changBox() {
      if (this.searchIns) {
        return
      }
      if (this.ischeck == undefined || this.searchIns) {
        this.ischeck = 0
        this.getdata()
        return
      }
      this.tableData = []
      this.tableData2.map((item) => {
        if (this.ischeck == 2 && item.status == 2) {
          this.tableData.push(item)
        } else if (this.ischeck == 1 && item.status == 1) {
          this.tableData.push(item)
        } else if (this.ischeck == 4 && item.failCount > 0) {
          this.tableData.push(item)
        }
      })
    },
  },
}
</script>
<style>
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
</style>
<style scoped>
@import '../../assets/style/Examine_CSS.css';
</style>
